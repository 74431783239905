import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { FluidContainer } from "../base/Container"
import { Title } from "../base/Text"

import Course from "./Course"

const Container = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.margins.xxl};
  width: 100%;
  background: ${props => props.theme.colors.grey200};
  padding: ${props => props.theme.margins.xxl} 0;
`

const InternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const query = graphql`
  query {
    allContentfulCourse(
      filter: { isPastCourse: { eq: true } }
      sort: { fields: [startDate], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          startDate
          endDate
          location {
            city
            country
          }
          courseBackground {
            fluid(maxWidth: 240, maxHeight: 160) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const PastCourses = props => (
  <StaticQuery
    query={query}
    render={data => {
      const courses = data.allContentfulCourse.edges
      const pastCourses = courses.map(course => course.node)

      return (
        <Container>
          <FluidContainer>
            <InternalContainer>
              <Title size="xxl" color="primary800" margin="xl" black center>
                What happened in the past...
              </Title>
              {pastCourses.map(course => (
                <Course key={course.slug} course={course} />
              ))}
            </InternalContainer>
          </FluidContainer>
        </Container>
      )
    }}
  />
)

export default PastCourses
