import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PoweredBy from "../components/poweredLogos/PoweredBy"
import JumboImage from "../components/home/JumboImage"
import USPs from "../components/home/USPs"
import NextCourses from "../components/next/NextCourses"
import Testimonials from "../components/testimonials/Testimonials"
import Trainers from "../components/trainers/Trainers"
import PastCourses from "../components/past/PastCourses"
import Logos from "../components/base/Logos"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <JumboImage />
      <USPs />
      <Logos />

      <NextCourses />
      <PoweredBy />
      <Testimonials
        testimonials={data.allContentfulTestimonial.edges.map(
          edge => edge.node
        )}
        color="primary"
      />
      <Trainers />
      <PastCourses />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulTestimonial(
      filter: { studentName: { in: ["Fernando Avendano", "Susanna Wong"] } }
    ) {
      edges {
        node {
          body {
            json
          }
          studentName
          studentTitle
          studentCompany
          studentProfileImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
