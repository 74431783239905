import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql, StaticQuery, Link } from "gatsby"
import { Subtitle } from "../base/Text"
import { getDateRange } from "../utils"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const query = graphql`
  query {
    allContentfulCourse(
      filter: { isBookingOpen: { eq: true } }
      sort: { fields: [startDate], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          startDate
          endDate
          location {
            city
          }
        }
      }
    }
  }
`

const ComingNext = props => (
  <StaticQuery
    query={query}
    render={data => {
      const courses = data.allContentfulCourse.edges.map(({ node }) => node)

      return (
        <Container>
          {courses.map(course => {
            const {
              title,
              startDate,
              endDate,
              location: { city },
              slug,
            } = course
            return (
              <Link to={`/${slug}/`} key={title}>
                <Fragment>
                  <Subtitle
                    color="primary100"
                    size="md"
                    margin="xs"
                    black
                    clickable
                  >
                    {title}
                  </Subtitle>
                  <Subtitle color="primary200" size="sm" margin="lg">
                    {`${getDateRange({ startDate, endDate })}, ${city}`}
                  </Subtitle>
                </Fragment>
              </Link>
            )
          })}
        </Container>
      )
    }}
  />
)

export default ComingNext
