import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Title, Subtitle } from "../base/Text"
import { getDateRange } from "../utils"

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: default;
  margin-bottom: ${props => props.theme.margins.lg};
  background: ${props => props.theme.colors.primary800};
  box-shadow: ${props => props.theme.shadows.strong};
`

const ImageContainer = styled.div`
  width: 240px;
  height: 160px;
  @media (max-width: ${props => props.theme.screen.mobile}) {
    width: 120px;
    height: 80px;
    overflow: hidden;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.lg};
  padding-left: ${props => props.theme.margins.xl};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding: ${props => props.theme.margins.nm};
  }
`

const Course = ({ course }) => {
  const {
    startDate,
    endDate,
    location: { city, country },
    slug,
  } = course

  return (
    <Container>
      <Link to={`/${slug}/`}>
        <ImageContainer>
          <Img fluid={course.courseBackground.fluid} />
        </ImageContainer>
      </Link>
      <Description>
        <Link to={`/${slug}/`}>
          <Title size="nm" color="primary150" margin="xs" black clickable>
            {course.title}
          </Title>
        </Link>
        <Subtitle size="sm" color="primary400">
          {`${getDateRange({
            startDate,
            endDate,
          })}, ${city}, ${country}`}
        </Subtitle>
      </Description>
    </Container>
  )
}

export default Course
