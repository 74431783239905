import React from "react"
import styled from "styled-components"
import { Title } from "../base/Text"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FluidContainer } from "../base/Container"

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  margin: ${props => props.theme.margins.xxl} 0;
`

const LogosLine = styled.div`
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: ${props => props.theme.margins.xl};
`

const LogosContainer = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
    align-items: center;
  }
`

const TitleLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.xl};
`

const Logo = styled.div`
  margin: 0 ${props => props.theme.margins.xl};
  img {
    max-height: 80px;
  }
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-bottom: ${props => props.theme.margins.xl};
  }
`

const query = graphql`
  query {
    thoughtram: file(relativePath: { eq: "powered/thoughtram.png" }) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    reactgraphqlacademy: file(
      relativePath: { eq: "powered/reactgraphqlacademy.png" }
    ) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hackages: file(relativePath: { eq: "powered/hackages.png" }) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const PoweredBy = () => (
  <StaticQuery
    query={query}
    render={data => {
      const teams = [
        {
          img: data.thoughtram.childImageSharp.fixed,
          url: "https://thoughtram.io/",
        },
        {
          img: data.reactgraphqlacademy.childImageSharp.fixed,
          url: "https://reactgraphql.academy/",
        },
        {
          img: data.hackages.childImageSharp.fixed,
          url: "https://hackages.io/",
        },
      ]

      return (
        <Container>
          <FluidContainer>
            <LogosLine>
              <TitleLine>
                {/* <IconContainer>
                  <BrainIcon />
                </IconContainer> */}
                <Title size="md" color="grey400" black>
                  Powered by
                </Title>
              </TitleLine>

              <LogosContainer>
                {teams.map((team, index) => (
                  <Logo key={`powered-by-${index}`}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={team.url}
                    >
                      <Img fixed={team.img} />
                    </a>
                  </Logo>
                ))}
              </LogosContainer>
            </LogosLine>
          </FluidContainer>
        </Container>
      )
    }}
  />
)

export default PoweredBy
