import React from "react"
import styled from "styled-components"

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Title } from "../base/Text"
import ComingNext from "./ComingNext"
import Rating from "../base/Stars"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  img {
    height: 600px;
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    height: 220px;
  }
`

const InternalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${props => props.theme.outerMargin};
`

const CenterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.theme.containers.lg};
  padding-bottom: 70px;
`

const InternalContainer = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-basis: 75%;
    max-width: 75%;
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    flex-basis: 100%;
    max-width: 100%;
  }
`

const RightContainer = styled.div`
  flex: 0 1 35%;
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.xl} 0
    ${props => props.theme.margins.xl} ${props => props.theme.margins.xl};
  border-left: 1px solid ${props => props.theme.colors.primary500};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    display: none;
  }
`

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
  }
`

const LandingTitle = styled(Title)`
  @media (max-width: ${p => p.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.lg};
  }
`

const LandingSubtitle = styled(Title)`
  margin-bottom: ${props => props.theme.margins.md};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.nm};
    margin-bottom: ${props => props.theme.margins.sm};
  }
`

//

const query = graphql`
  query {
    jumbo: file(relativePath: { eq: "jumbo/home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const JumboImage = () => (
  <StaticQuery
    query={query}
    render={data => {
      const jumbo = data.jumbo.childImageSharp.fluid
      return (
        <Wrapper>
          <BgImg fluid={jumbo} loading="eager" />
          <InternalWrapper>
            <CenterContainer>
              <InternalContainer>
                <LandingTitle
                  size="xxxl"
                  color="primary100"
                  black
                  lineHeight={1}
                >
                  Become
                </LandingTitle>
                <LandingTitle
                  size="xxxl"
                  color="primary100"
                  black
                  lineHeight={1}
                  margin="lg"
                >
                  a senior developer
                </LandingTitle>

                <LandingSubtitle size="lg" color="primary100" lineHeight={1.5}>
                  One-week accelerated training for career-minded developers
                </LandingSubtitle>
                <Rating />
              </InternalContainer>
              <RightContainer>
                <Title size="sm" color="primary200" margin="lg" uppercase>
                  Upcoming Courses
                </Title>

                <ComingNext />
              </RightContainer>
            </CenterContainer>
          </InternalWrapper>
        </Wrapper>
      )
    }}
  />
)

export default JumboImage
