import React from "react"
import styled from "styled-components"
import { FluidContainer } from "../base/Container"
import { graphql, StaticQuery } from "gatsby"
import { Title } from "../base/Text"
import Trainer from "./Trainer"

const TrainersWrapper = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
  background: ${props => props.theme.colors.primary900};
`

const TrainersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: ${props => props.theme.containers.lg};
`

const query = graphql`
  query {
    allContentfulTrainer {
      edges {
        node {
          name
          title
          company
          icon
          bio {
            bio
          }
          profileImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Trainers = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const trainers = data.allContentfulTrainer.edges.map(
          trainer => trainer.node
        )
        return (
          <TrainersWrapper>
            <FluidContainer>
              <Title size="xl" color="primary200" margin="xl" black center>
                Meet Your Trainers
              </Title>
              <TrainersContainer>
                {trainers.map(trainer => (
                  <Trainer key={trainer.name} trainer={trainer} />
                ))}
              </TrainersContainer>
            </FluidContainer>
          </TrainersWrapper>
        )
      }}
    />
  )
}

export default Trainers
