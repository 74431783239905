import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Subtitle } from "../base/Text"
import { trainersIcons } from "../base/Trainers"

const Container = styled.div`
  position: relative;
  flex: 1 1 calc(25% - 1.5rem);
  display: flex;
  flex-direction: column;
  max-width: calc(25% - 1.5rem);
  margin-right: ${props => props.theme.outerMargin};
  box-shadow: ${props => props.theme.shadows.strong};
  margin-bottom: ${props => props.theme.outerMargin};
  background: ${props => props.theme.colors.primary600};
  &:nth-child(4n) {
    margin-right: 0;
  }
  @media (max-width: ${p => p.theme.screen.medium}) {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin-right: ${props => props.theme.outerMargin};
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 0;
  }
`

const TrainerLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.nm};
`

const IconContainer = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.margins.md};
  svg {
    width: 28px;
    height: 28px;
    stroke: ${props => props.theme.colors.primary400};
    fill: ${props => props.theme.colors.primary600};
  }
`

const TrainerInfo = styled.span`
  padding: ${props => props.theme.margins.lg};
  background: ${props => props.theme.colors.primary600};
  display: flex;
  flex-direction: column;
  justify-self: stretch;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme.colors.primary600};
  opacity: 0.7;
  mix-blend-mode: color;
`

const Trainer = ({ trainer }) => {
  const TrainerIcon = trainersIcons[trainer.icon]

  return (
    <Container>
      <Img fluid={trainer.profileImage.fluid} />
      <Overlay />

      <TrainerInfo>
        <TrainerLine>
          <IconContainer>
            <TrainerIcon />
          </IconContainer>
          <Subtitle size="md" color="primary200" margin="sm" black>
            {trainer.name}
          </Subtitle>
        </TrainerLine>
        <Subtitle size="sm" color="primary300">
          {`${trainer.title} at ${trainer.company}`}
        </Subtitle>
      </TrainerInfo>
    </Container>
  )
}

export default Trainer
