import React from "react"
import styled from "styled-components"
import { FluidContainer } from "../base/Container"
import { Title, Subtitle } from "../base/Text"
import { TeamIcon, DeveloperIcon, TickIcon } from "../base/Icons"

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${props => props.theme.containers.lg};
  justify-content: center;
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex-direction: column;
  }
`

const ValuePropositionContainer = styled.div`
  flex: 1 1 45%;
  padding: ${props => props.theme.margins.xl};
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.lg};
  margin-top: -50px;
  border-top: 8px solid ${props => props.theme.colors.primary500};
  &:first-of-type {
    margin-right: 5%;
  }

  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex: 1 1 100%;
    margin-top: ${props => props.theme.margins.lg};
    &:first-of-type {
      margin-top: -50px;
      margin-right: 0;
    }
  }
`

const LineWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.lg};
`

const IconContainer = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.margins.lg};
  svg {
    width: 48px;
    height: 48px;
    stroke: ${props => props.theme.colors.primary300};
    fill: ${props => props.theme.colors.primary100};
  }
`

const VPLine = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.margins.md};
  margin-bottom: ${props => props.theme.margins.md};
`

const VPIconContainer = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.margins.md};
  svg {
    width: 18px;
    height: 18px;
    stroke: ${props => props.theme.colors.primary300};
    /* fill: ${props => props.theme.colors.primary300}; */

    fill: transparent;
  }
`

const USPs = () => (
  <FluidContainer>
    <Container>
      <ValuePropositionContainer>
        <LineWithIcon>
          <IconContainer>
            <TeamIcon />
          </IconContainer>
          <Title size="xxl" color="primary500" black>
            Teams
          </Title>
        </LineWithIcon>

        <Subtitle size="md" color="grey900" margin="lg" bold>
          In just one week your team will
        </Subtitle>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Master a new set of skills
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Be ready for the next project at your company
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Become confident and resourceful
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Learn the best practices
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Improve their team-working skills
          </Subtitle>
        </VPLine>
      </ValuePropositionContainer>

      <ValuePropositionContainer>
        <LineWithIcon>
          <IconContainer>
            <DeveloperIcon />
          </IconContainer>
          <Title size="xxl" color="primary500" margin="nm" black>
            Developers
          </Title>
        </LineWithIcon>

        <Subtitle size="md" color="grey900" margin="lg" bold>
          Learn and keep your career moving
        </Subtitle>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            40+ hours of hands-on training
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            One week of focus without distractions
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            World-class trainers
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Unique co-working space
          </Subtitle>
        </VPLine>

        <VPLine>
          <VPIconContainer>
            <TickIcon />
          </VPIconContainer>
          <Subtitle size="nm" color="grey900">
            Additional activities to recharge your batteries
          </Subtitle>
        </VPLine>
      </ValuePropositionContainer>
    </Container>
  </FluidContainer>
)

export default USPs
